<template>
    <div class="pagination">
        <el-pagination
            v-if='paginations.pageTotal > 0'
            background
            :page-sizes="paginations.pageSizes"
            :page-size="paginations.pageSize"
            :layout="paginations.layout"
            :total="paginations.pageTotal"
            :current-page='paginations.pageIndex'
            @current-change='handleCurrentChange'
            @size-change='handleSizeChange'>
        </el-pagination>
    </div>
</template>


<script>
export default {
    name:'pagination',
    data(){
        return {
            paginations: {},
        }
    },
    props:{
        paginationInfo:Object
    },
    created(){},
    mounted(){
        this.paginations = this.paginationInfo
    },
    watch: {
        paginationInfo:{
            handler(newV,oldV) {
                this.paginations = newV
            },
            deep:true
        }
    },
    methods:{ 
        // 上下分页 pageIndex
        handleCurrentChange(page){
            this.$emit('handleCurrentChange',page);
        },
        // 每页多少条切换 pageSize
        handleSizeChange(page_size){
            this.$emit('handleSizeChange',page_size);
        }
    }
}
</script>

